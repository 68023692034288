import React from "react";
import ReactDOM from "react-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"
import Nav from "react-bootstrap/Nav"
import "./css/main.css"

class App extends React.Component {

    constructor(props) {
        super(props);
        this.divElement = React.createRef();
        this.footer = React.createRef();
        this.state = {
            contentCss: { height: 0 },
            containerCss: { height: 0 },
            footerCss: { height: 0 }
        };
    }

    componentDidMount() {
        var containerHeight = window.innerHeight;

        var contentBodyHeight = this.divElement.clientHeight;
        console.log(contentBodyHeight)
        var contentBodyPos = containerHeight / 2 - contentBodyHeight / 2;

        this.setState({
            containerCss: { height: containerHeight },
            contentCss: { height: contentBodyPos },
            footerCss: { height: this.footer.clientHeight }
        })
    }

    render() {

        return (
            <Container>
                <Row style={{ height: this.state.containerCss.height }}>
                    <Col>
                        <div className="text-center" style={{ marginTop: this.state.contentCss.height }} ref={(divElement) => { this.divElement = divElement }}>
                            <h1>Connect to your remote device with SSH.</h1>
                            <h3>This is the easiest thing ever.</h3><br />
                            <Button size="lg" href="http://www.mediafire.com/file/ukznx1a4tz8v4ra/RpiConnectLite.jar/file" variant='success'>Download</Button>{'   '}
                            <Button size="lg" variant='primary' onClick={s => {
                                var elmnt = document.getElementById("footer");
                                elmnt.scrollIntoView({ behavior: "smooth", block: "start" });
                            }}>How to use</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div id="footer" 
                        className="text-center footer" 
                        style={{ marginTop: -this.state.footerCss.height}}
                        ref={(footer) => this.footer = footer}
                        >
                            <Nav.Link href="https://github.com/shn-dev/RpiConnectLite">Check out the source code</Nav.Link>
                                <Image className="bsicon" src="./img/bsicon.png" />
                                <p className="footer-text">Big Software, LLC, &#169; 2020</p>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md={6}>
                            <h2>1. Input Connection Info.</h2>
                            <p>You need to enter the following information to connect to your remote device (i.e. Raspberry Pi, Arduino, etc)</p>
                            <ul>
                                <li>
                                    Username
                                </li>
                                <li>
                                    Password
                                </li>
                                <li>Host (IP) address</li>
                                <li>Port</li>
                                <li>Working directory</li>
                            </ul>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image className="img-buffer" fluid src="./img/screen1.png"/>
                        </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md={{span:6, order:'last'}}>
                            <h2>
                                2. Double-click the file to edit.
                            </h2>
                            <p>The file will be opened on your computer with the default application. For example, if you edit a .java file, Visual Studio will open.</p>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image className="img-buffer" fluid src="./img/screen2.png"/>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col md={6}>
                            <h2>
                                3. Save your file.
                            </h2>
                            <p>
                                Whenever you save the changes are automatically updated on your remote device. Easy!
                            </p>
                    </Col>
                    <Col md={6} className="text-center">
                        <Image fluid src="./img/screen3.png"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                            <h2 style={{padding:32}}>That's it. <strong>Enjoy!</strong></h2>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default App